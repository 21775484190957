@font-face {
    font-family: "Archivo";
    src: url("assets/fonts/Archivo-Bold.ttf");
}
@font-face {
    font-family: "Archivo";
    src: url("assets/fonts/Archivo-Medium.ttf");
}

@font-face {
    font-family: "Archivo";
    src: url("assets/fonts/Archivo-Regular.ttf");
}

html {
    height: 100%;
    width: 100%;
}

body {
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
    padding: 0!important;
    overflow: auto;
    margin: 0;
    font-family: "Archivo", serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}


::-webkit-scrollbar{
    background: #f1f1f1;
    height: 4px;
    width: 4px;
}
::-webkit-scrollbar-thumb:horizontal{
    background: rgb(129, 127, 127);
    border-radius: 10px;
}
